import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-whatsapp',
  templateUrl: './whatsapp.component.html',
  styleUrls: ['./whatsapp.component.scss']
})
export class WhatsappComponent implements OnInit {
  isChatVisible = false;
  whatsappForm: FormGroup;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.whatsappForm = this.fb.group({
      message: ['']
    });
  }

  toggleChat(): void {
    this.isChatVisible = !this.isChatVisible;
  }

  getWhatsAppLink(): string {
    const message = this.whatsappForm.get('message') ? this.whatsappForm.get('message').value : '';
    return `https://api.whatsapp.com/send?phone=353899883159&text=${encodeURIComponent(message)}`;
  }

  sendMessage(): void {
    if (this.whatsappForm.valid) {
      window.open(this.getWhatsAppLink(), '_blank');
      this.whatsappForm.reset();
    }
  }
}
