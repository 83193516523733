import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtModule } from '@auth0/angular-jwt';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { LoginComponent } from './static/login/login.component';

import { ReactiveFormsModule } from '@angular/forms';

import { HttpConfigInterceptor } from './core/helpers/http.interceptor.service';
import { NotifierModule } from 'angular-notifier';
import { UsersService } from './core/services/users.service';

import { BrandtestComponent } from './brandtest/brandtest.component';
import { SectionStaticHomeComponent } from './static/home/home.component';
import { SectionStaticPricesComponent } from './static/prices/precios.component';
import { SectionStaticAboutusComponent } from './static/aboutus/aboutus.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ButtonElement } from './elements/button/button.component';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { UploadDragDropDirective } from './core/directives/upload-drag-drop.directive';
import { SimplicityComponent } from './brandtest/results/views-results/shape/simplicity/simplicity.component';
import { HarmonyComponent } from './brandtest/results/views-results/color/harmony/harmony.component';
import { VisibilityComponent } from './brandtest/results/views-results/color/visibility/visibility.component';
import { ProyectsComponent } from './proyects/proyects.component';
import { RouterModule } from '@angular/router';
import { UploadlogoComponent } from './brandtest/addtest/uploadlogo/uploadlogo.component';
import { ImgComponent } from './elements/img/img.component';
import { ReadabilityComponent } from './brandtest/results/views-results/shape/readability/readability.component';
import { DensityComponent } from './brandtest/results/views-results/proportions/density/density.component';
import { PvisibilityComponent } from './brandtest/results/views-results/proportions/pvisibility/pvisibility.component';
import { CcolorComponent } from './brandtest/results/views-results/competition/ccolor/ccolor.component';
import { DigitalComponent } from './brandtest/results/views-results/compatibility/digital/digital.component';
import { PrintedComponent } from './brandtest/results/views-results/compatibility/printed/printed.component';
import { CshapeComponent } from './brandtest/results/views-results/competition/cshape/cshape.component';
import { LegalComponent } from './brandtest/results/views-results/naming/legal/legal.component';
import { UploadnameComponent } from './brandtest/addtest/uploadname/uploadname.component';
import { LinguisticComponent } from './brandtest/results/views-results/naming/linguistic/linguistic.component';
import { AudioComponent } from './elements/audio/audio.component';
import { BalancingComponent } from './brandtest/results/views-results/shape/balancing/balancing.component';
import { CbalancingComponent } from './brandtest/results/views-results/color/cbalancing/cbalancing.component';
import { WindowPopUpComponent } from './elements/window-pop-up/window-pop-up.component';
import { RegisterComponent } from './static/register/register.component';
import { ForgotpasswordComponent } from './static/forgotpassword/forgotpassword.component';
import { TbtBarComponent } from './elements/tbt-bar/tbt-bar.component';
import { WindowResultComponent } from './elements/window-result/window-result.component';
import { AddtestComponent } from './brandtest/addtest/addtest.component';
import { ShapeComponent } from './brandtest/results/views-results/shape/shape.component';
import { NamingComponent } from './brandtest/results/views-results/naming/naming.component';
import { ColorComponent } from './brandtest/results/views-results/color/color.component';
import { CompatibilityComponent } from './brandtest/results/views-results/compatibility/compatibility.component';
import { CompetitionComponent } from './brandtest/results/views-results/competition/competition.component';
import { ProportionsComponent } from './brandtest/results/views-results/proportions/proportions.component';
import { BoxResultComponent } from './brandtest/results/box-result/box-result.component';
import { BoxResultSimulatedComponent } from './brandtest/results/box-result-simulated/box-result-simulated.component';


import { LandingComponent } from './static/landing/landing.component';
import { AnalizamosComponent } from './static/weanalyze/analizamos.component';


import {Ng2FittextModule} from "ng2-fittext";
import { CsimplicityComponent } from './brandtest/results/views-results/color/csimplicity/csimplicity.component';
import { PerformanceComponent } from './brandtest/results/views-results/shape/performance/performance.component';
import { SystemColorComponent } from './brandtest/results/views-results/color/system-color/system-color.component';
import { ColorimetryComponent } from './brandtest/results/views-results/color/colorimetry/colorimetry.component';
import { ShoppingcartComponent } from './proyects/shoppingcart/shoppingcart.component';
import { SuccessfulpaymentComponent } from './static/successfulpayment/successfulpayment.component';
import { AccountComponent } from './account/account.component';
import { CreateReportComponent } from './brandtest/reports/create-report/create-report.component';
import { ShowReportComponent } from './brandtest/reports/show-report/show-report.component';
import { BetalandingComponent } from './static/betalanding/betalanding.component';
import { MenuComponent } from './static/menu/menu.component';
import { FooterComponent } from './static/footer/footer.component';
import { EntitiesComponent } from './static/entities/entities.component';

import { SectionStaticHowtouseComponent } from './static/howtouse/como-usa.component';
import { SectionStaticUsesCasesComponent } from './static/usecases/casos.component';


// Import ngx-barcode module
import { NgxBarcodeModule } from 'ngx-barcode';
import { ListReportsComponent } from './proyects/list-reports/list-reports.component';
import { CategorySeparatorComponent } from './brandtest/results/views-results/category-separator/category-separator.component';
import { ListTestsComponent } from './account/list-tests/list-tests.component';
import { InputTagsComponent } from './elements/input-tags/input-tags.component';
import { from } from 'rxjs';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// FontAwesome
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faInstagram, faFacebook, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faSearchDollar, faCertificate,  faDollarSign, faCoins, faFileInvoiceDollar} from '@fortawesome/free-solid-svg-icons';
import { faClock, faAddressCard} from '@fortawesome/free-regular-svg-icons';
import { FailedpaymentComponent } from './static/failedpayment/failedpayment.component';
import { BarScoreComponent } from './brandtest/results/views-results/shape/simplicity/bar-score/bar-score.component';
import { CircleScoreComponent } from './brandtest/results/views-results/shape/simplicity/circle-score/circle-score.component';
import { BarMinScoreComponent } from './brandtest/results/views-results/shape/simplicity/bar-min-score/bar-min-score.component';
import { InfoPopupComponent } from './elements/info-popup/info-popup.component';
import { CookieService } from 'ngx-cookie-service';
import { NgxFileDropModule } from 'ngx-file-drop';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { SimplicitySummaryComponent } from './brandtest/results/views-results/shape/simplicity-summary/simplicity-summary.component';
import {NgcCookieConsentModule, NgcCookieConsentConfig} from 'ngx-cookieconsent';
import { TermsandconditionsComponent } from './static/termsandconditions/termsandconditions.component';
import { ProgressBarComponent } from './brandtest/results/views-results/shape/simplicity/progress-bar/progress-bar.component';
import { ModalImageComponent } from './brandtest/results/views-results/shape/simplicity/modal-image/modal-image.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { PaymentprocessComponent } from './proyects/paymentprocess/paymentprocess.component';
import { EditprojectComponent } from './proyects/editproject/editproject.component';
import { AppointmentComponent } from './static/appointment/appointment.component';
import { SuccessfulAppointmentComponent } from './static/successful-appointment/successful-appointment.component';
import { TransactionsComponent } from './static/transactions/transactions.component';
import { CountdownTimerComponent } from './elements/countdown-timer/countdown-timer.component';
import { WhatsappComponent } from './elements/whatsapp/whatsapp.component';


export function getToken() {
  return localStorage.getItem('access_token'); 
}

export function HttpLoaderFactory(http:HttpClient) {
  return new TranslateHttpLoader(http);
}  

const cookieConfig:NgcCookieConsentConfig = {
  cookie: {
    domain: 'betester.com' // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
  },
  palette: {
    popup: {
      background: '#000'
    },
    button: {
      background: '#F3E03B'
    }
  },
  theme: 'edgeless',
  type: 'info',
  content: {
    //href: "https://betester.com/termsandconditions/cookie",
    href: "http://localhost:4200/termsandconditions/cookie",

  }
};

@NgModule({
  declarations: [
    AppComponent,
    SectionStaticPricesComponent,
    SectionStaticHomeComponent,
    SectionStaticAboutusComponent,
    SectionStaticUsesCasesComponent,
    LoginComponent,
    BrandtestComponent,
    ButtonElement,
    UploadDragDropDirective,
    SimplicityComponent,
    HarmonyComponent,
    VisibilityComponent,
    ProyectsComponent,
    EditprojectComponent,
    UploadlogoComponent,
    ImgComponent,
    ReadabilityComponent,
    DensityComponent,
    PvisibilityComponent,
    CcolorComponent,
    DigitalComponent,
    PrintedComponent,
    CshapeComponent,
    LegalComponent,
    UploadnameComponent,
    LinguisticComponent,
    AudioComponent,
    BalancingComponent,
    CbalancingComponent,
    WindowPopUpComponent,
    RegisterComponent,
    ForgotpasswordComponent,
    TbtBarComponent,
    WindowResultComponent,
    AddtestComponent,
    ShapeComponent,
    NamingComponent,
    ColorComponent,
    CompatibilityComponent,
    CompetitionComponent,
    ProportionsComponent,
    BoxResultComponent,
    BoxResultSimulatedComponent,
    SectionStaticHowtouseComponent,
    LandingComponent,
    CsimplicityComponent,
    PerformanceComponent,
    SystemColorComponent,
    ColorimetryComponent,
    ShoppingcartComponent,
    SuccessfulpaymentComponent,
    AccountComponent,
    CreateReportComponent,
    ShowReportComponent,
    BetalandingComponent,
    ListReportsComponent,
    CategorySeparatorComponent,
    ListTestsComponent,
    InputTagsComponent,
    MenuComponent,
    FooterComponent,
    EntitiesComponent,
    FailedpaymentComponent,
    AnalizamosComponent,
    BarScoreComponent,
    CircleScoreComponent,
    BarMinScoreComponent,
    InfoPopupComponent,
    SimplicitySummaryComponent,
    TermsandconditionsComponent,
    ProgressBarComponent,
    ModalImageComponent,
    ConfirmComponent,
    PaymentprocessComponent,
    AppointmentComponent,
    SuccessfulAppointmentComponent,
    TransactionsComponent,
    CountdownTimerComponent,
    WhatsappComponent

  ],
  imports: [
    RouterModule,
    BrowserModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    NotifierModule.withConfig( {
      position: {
        horizontal: {
          position: 'middle',
          distance: 12
        },
        vertical: {
          position: 'top',
          distance: 12,
          gap: 10
        }
      }
    }),
    ImageCropperModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: getToken,
        whitelistedDomains: ['api.tbt.org','beta.betester.com','betester.com','localhost:8012'],
        blacklistedRoutes: ['api.tbt.org/auth/login','beta.betester.com/auth/login','betester.com/auth/login','localhost:8012/auth/login']
      }
    }),
    BrowserAnimationsModule,
    ScrollToModule.forRoot(),
    CarouselModule.forRoot(),
    Ng2FittextModule,
    NgxBarcodeModule,
    NgbModule,
    FontAwesomeModule,
    NgxFileDropModule,
    TranslateModule.forRoot({
      loader:{
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]

      }
    }),
    NgcCookieConsentModule.forRoot(cookieConfig)
  ],
  providers: [UsersService, { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true }, CookieService],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    // Add an icon to the library for convenient access in other components
    library.add(faInstagram, faFacebook, faTwitter, faYoutube, faSearchDollar, faClock, faCertificate,  faDollarSign, faAddressCard, faCoins, faFileInvoiceDollar);
  } 
}
